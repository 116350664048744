export const ACCOUNT_TYPES = {
  STUDENT: '0',
  SYSTEM_ADMIN: '1',
  UNIVERSITY_USER: '10',
  UNIVERSITY_ADMIN: '11',
  STORE_USER: '20',
  STORE_ADMIN: '21',
};

export const STUDENT_STATUSES = {
  REGISTRATION: 1,
  LIMITED: 2,
  GRADUATE: 3,
  SOFT_DELETE: 4,
  WITHDRAWAL: 6,
  BLOCK: 5,
  TEMPORARY_REGISTRATION: 7,
};

export const GENDER = {
  1: "男",
  2: "女",
  0: "その他",
};

export const SCHOOL_STATUSES = {
  REGISTRATION: 1,
  SOFT_DELETE: 4,
  BLOCK: 5,
};

export const STORE_STATUSES = {
  REGISTRATION: 1,
  SOFT_DELETE: 4,
};

export const STORE_STATUSES_TEXT = {
  1: '本登録',
  4: '閉鎖',
};

export const PATH_URL = {
  STUDENT: '/student',
  STORE: '/store',
  SCHOOL: '/school',
  SYSTEM: '/system',
  FACULTY: '/faculty',
  GENERAL: '/general',
  SETTING: '/setting',
  BANK: 'https://apis.bankcode-jp.com/v2'
};

export const STATUS_POINT: any = {
  1: 'ポイント付与',
  2: 'ポイント付与取消',
  3: 'ポイ ント利用',
  4: 'ポイント返還',
};

export const STATUS_USED_POINT: any = {
  1: '支払済',
  2: '返還済',
};

export const CHECK_HISTORY = {
  AUTO: 1,
  SCHOOL: 2
};


export const OPERATION_USER_POINT = {
  USER: '手動付与',
  SYSTEM: '自動付与',
};

export const DATA_POINT_KEY = {
  TIME: 0,
  STATUS: 1,
  OPERATION_USER: 2,
  TOTAL_USER: 3,
  POINT: 4,
  TOTAL_POINT: 5
};

export const TOKEN_STATUS = {
  EXPIRED: 1,
  USED: 2,
};

export const STORE_IMAGE_TYPES = {
  LOGO: 5,
  TOP: 1,
  STORE: 4,
  MENU: 2,
  OTHER: 3,
};

export const DATE_FORMAT = {
  MONTH_YEAR: 'MM/yyyy',
  YEAR: 'yyyy',
  MONTH: 'MM',
};

export const SETTINGS = {
  AREA: '1',
};

export const AREAS = ["北海道", "東北", "関東", "中部", "近畿", "中国", "四国", "九州沖縄"];

export const HISTORY_POINT_TYPES = {
  ALL: {
    name: "すべて",
    value: 1,
    tab:1
  },
  USED: {
    name: "利用",
    value: 2,
    tab:2
  },
  GIVE: {
    name: "付与",
    value: 3,
    tab: 3
  },
  REFUND: {
    name: "返還",
    value: 4,
    tab: 2
  },
  CANCEL: {
    name: "取消",
    value: 5,
    tab: 3
  }
};

export const DISPLAY_FLAGS = {
  SHOW: 1,
  HIDE: 0,
}
export const ACCOUNT_TYPE: any = {
  1: '普通預金',
  2: '当座預金',
};

export const BANK_TYPE: any = {
  FINANCE: 1,
  BANK: 2,
};

export const FIELDS = [
  { label: '本登録日時', name: 'activeAt', status: STUDENT_STATUSES.REGISTRATION },
  { label: '閉鎖日時', name: 'deletedAt', status: STUDENT_STATUSES.SOFT_DELETE },
  // { label: '卒業日時', name: 'updatedAt', status: STUDENT_STATUSES.GRADUATE },
  { label: '一時停止日時', name: 'blockedAt', status: STUDENT_STATUSES.BLOCK }
];

export const DATA_IMPORT_STUDENT = {
  gakusekiNo: '学籍番号',
  name: '名前',
  kana: '名前カナ',
  facultyName: '学部(グループ)',
  birth: '生年月日',
  nyugaku: '入学年',
  gakunen: '学年',
  genderName: '性別',
  tel: '電話番号',
  email: 'メールアドレス',
  zip: '郵便番号',
  pref: '都道府県',
  city: '市区町村',
  addr: '番地',
  bldg: '建物名',
};

export const DATA_IMPORT_STAFF = {
  email: 'メールアドレス',
  name: 'スタッフ名',
  roleUser: '管理権限',
  password: "パスワード"
};

export const ROUTE_ROLES = {
  SCHOOL: 'school',
  STORE: 'store',
  SYSTEM: 'system',
};

export const STAFF_ROLES = {
  ADMIN: '管理者',
  STAFF: 'スタッフ',
};

export const SALES_RECEIPT_STATUSES = {
  1: '未入金',
  2: '入金手続き中',
  3: '入金済',
  4: '入金失敗',
};

export const BANK_MODALS = {
  BANK: 'modalSearchBank',
  BRANCH: 'modalSearchBranch',
}

export const DEFAULT_REFUND_DATE = 14;

export const DEFAULT_CENTER_MAP = {
  lat: 35.6869168,
  lng: 139.7357796,
}

export const ACTIVE_RADIO = {
  active: 1,
  inActive: 0
}
export const MAIL_TYPES = {
  EXPIRED: {
    name: "有効期限切れ",
    value: 1,
  },
  GIVE: {
    name: "ポイント付与",
    value: 2,
  },
  USED: {
    name: "ポイント利用",
    value: 3,
  },
  REFUND: {
    name: "ポイント返還",
    value: 4,
  },
};
